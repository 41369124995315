import router from '../router'
import { createStore } from 'vuex'
import axios from 'axios';

export default createStore({
    state: {
        packageVersion: '1.240507',
        loggedIn: false,
        endPointFailure: false,
        clearStatus: 'endcustomer',
        userGroups: ['endcustomer'],
        partnerPalette: {},
        partnerPaletteBuilt: false,
        main: {
            colors: {
                flagship: {
                    primary:        '#69c350',
                    secondary:      '#d9f0d3',
                    next_primary:   '#69c350',
                    next_secondary: '#d9f0d3',
                },
                platin: {
                    primary:        '#A5958A',
                    secondary:      '#e8e4e1',
                    next_primary:   '#69c350',
                    next_secondary: '#d9f0d3',
                },
                gold: {
                    primary:        '#D5B77E',
                    secondary:      '#f4edde',
                    next_primary:   '#A5958A',
                    next_secondary: '#e8e4e1',
                },
                silver: {
                    primary:        '#9A9DA0',
                    secondary:      '#e5e6e7',
                    next_primary:   '#D5B77E',
                    next_secondary: '#f4edde',
                },
                registered: {
                    primary:        '#B3B5B7',
                    secondary:      '#ececed',
                    next_primary:   '#9A9DA0',
                    next_secondary: '#e5e6e7',
                },
                endcustomer: {
                    primary:        '#B3B5B7',
                    secondary:      '#ececed',
                    next_primary:   '#B3B5B7',
                    next_secondary: '#ececed',
                },
            }
        },
        loginErrorMessage: '',
        loginRequestState: '',
        userData: {
            loaded: false,
            loaded_from: '',
            content: {},
        },
        partnerData: {
            loaded: false,
            loaded_from: '',
            content: {},
        },
        trainingsObject: {
            loaded: false,
            loaded_from: '',
            content: {},
        },
        ordersObject: {
            loaded: false,
            loaded_from: '',
            content: {},
        },
        hotlinks: {
            loaded: false,
            loaded_from: '',
            content: {},
        },
        contactData: {
            loaded: false,
            loaded_from: '',
            content: {
            },
        },
        notifications: {
            loaded: false,
            loaded_from: '',
            content: {
            },
        },
        bannerData: {
            loaded: false,
            loaded_from: '',
            content: {
            },
        },
        countries: {
            "AF": "Afghanistan",
            "AL": "Albania",
            "DZ": "Algeria",
            "AS": "American Samoa",
            "AD": "Andorra",
            "AO": "Angola",
            "AI": "Anguilla",
            "AQ": "Antarctica",
            "AG": "Antigua and Barbuda",
            "AR": "Argentina",
            "AM": "Armenia",
            "AW": "Aruba",
            "AU": "Australia",
            "AT": "Österreich",
            "AZ": "Azerbaijan",
            "BS": "Bahamas",
            "BH": "Bahrain",
            "BD": "Bangladesh",
            "BB": "Barbados",
            "BE": "Belgium",
            "BZ": "Belize",
            "BJ": "Benin",
            "BM": "Bermuda",
            "BT": "Bhutan",
            "BO": "Bolivia (Plurinational State of)",
            "BQ": "Bonaire, Sint Eustatius and Saba",
            "BA": "Bosnia and Herzegovina",
            "BW": "Botswana",
            "BV": "Bouvet Island",
            "BR": "Brazil",
            "IO": "British Indian Ocean Territory",
            "BN": "Brunei Darussalam",
            "BG": "Bulgaria",
            "BF": "Burkina Faso",
            "BI": "Burundi",
            "CV": "Cabo Verde",
            "KH": "Cambodia",
            "CM": "Cameroon",
            "CA": "Canada",
            "KY": "Cayman Islands",
            "CF": "Central African Republic",
            "TD": "Chad",
            "CL": "Chile",
            "CN": "China",
            "CX": "Christmas Island",
            "CC": "Cocos (Keeling) Islands",
            "CO": "Colombia",
            "KM": "Comoros",
            "CD": "Congo (the Democratic Republic of the)",
            "CG": "Congo",
            "CK": "Cook Islands",
            "CR": "Costa Rica",
            "HR": "Croatia",
            "CU": "Cuba",
            "CW": "Curaçao",
            "CY": "Cyprus",
            "CZ": "Czechia",
            "CI": "Côte d'Ivoire",
            "DK": "Denmark",
            "DJ": "Djibouti",
            "DM": "Dominica",
            "DO": "Dominican Republic",
            "EC": "Ecuador",
            "EG": "Egypt",
            "SV": "El Salvador",
            "GQ": "Equatorial Guinea",
            "ER": "Eritrea",
            "EE": "Estonia",
            "SZ": "Eswatini",
            "ET": "Ethiopia",
            "FK": "Falkland Islands",
            "FO": "Faroe Islands",
            "FJ": "Fiji",
            "FI": "Finland",
            "FR": "France",
            "GF": "French Guiana",
            "PF": "French Polynesia",
            "TF": "French Southern Territories",
            "GA": "Gabon",
            "GM": "Gambia",
            "GE": "Georgia",
            "DE": "Deutschland",
            "GH": "Ghana",
            "GI": "Gibraltar",
            "GR": "Greece",
            "GL": "Greenland",
            "GD": "Grenada",
            "GP": "Guadeloupe",
            "GU": "Guam",
            "GT": "Guatemala",
            "GG": "Guernsey",
            "GN": "Guinea",
            "GW": "Guinea-Bissau",
            "GY": "Guyana",
            "HT": "Haiti",
            "HM": "Heard Island and McDonald Islands",
            "VA": "Holy See",
            "HN": "Honduras",
            "HK": "Hong Kong",
            "HU": "Hungary",
            "IS": "Iceland",
            "IN": "India",
            "ID": "Indonesia",
            "IR": "Iran (Islamic Republic of)",
            "IQ": "Iraq",
            "IE": "Ireland",
            "IM": "Isle of Man",
            "IL": "Israel",
            "IT": "Italy",
            "JM": "Jamaica",
            "JP": "Japan",
            "JE": "Jersey",
            "JO": "Jordan",
            "KZ": "Kazakhstan",
            "KE": "Kenya",
            "KI": "Kiribati",
            "KP": "Korea (the Democratic People's Republic of)",
            "KR": "Korea (the Republic of)",
            "KW": "Kuwait",
            "KG": "Kyrgyzstan",
            "LA": "Lao People's Democratic Republic",
            "LV": "Latvia",
            "LB": "Lebanon",
            "LS": "Lesotho",
            "LR": "Liberia",
            "LY": "Libya",
            "LI": "Liechtenstein",
            "LT": "Lithuania",
            "LU": "Luxembourg",
            "MO": "Macao",
            "MG": "Madagascar",
            "MW": "Malawi",
            "MY": "Malaysia",
            "MV": "Maldives",
            "ML": "Mali",
            "MT": "Malta",
            "MH": "Marshall Islands",
            "MQ": "Martinique",
            "MR": "Mauritania",
            "MU": "Mauritius",
            "YT": "Mayotte",
            "MX": "Mexico",
            "FM": "Micronesia (Federated States of)",
            "MD": "Moldova (the Republic of)",
            "MC": "Monaco",
            "MN": "Mongolia",
            "ME": "Montenegro",
            "MS": "Montserrat",
            "MA": "Morocco",
            "MZ": "Mozambique",
            "MM": "Myanmar",
            "NA": "Namibia",
            "NR": "Nauru",
            "NP": "Nepal",
            "NL": "Netherlands",
            "NC": "New Caledonia",
            "NZ": "New Zealand",
            "NI": "Nicaragua",
            "NE": "Niger",
            "NG": "Nigeria",
            "NU": "Niue",
            "NF": "Norfolk Island",
            "MP": "Northern Mariana Islands",
            "NO": "Norway",
            "OM": "Oman",
            "PK": "Pakistan",
            "PW": "Palau",
            "PS": "Palestine, State of",
            "PA": "Panama",
            "PG": "Papua New Guinea",
            "PY": "Paraguay",
            "PE": "Peru",
            "PH": "Philippines",
            "PN": "Pitcairn",
            "PL": "Poland",
            "PT": "Portugal",
            "PR": "Puerto Rico",
            "QA": "Qatar",
            "MK": "Republic of North Macedonia",
            "RO": "Romania",
            "RW": "Rwanda",
            "RE": "Réunion",
            "BL": "Saint Barthélemy",
            "SH": "Saint Helena, Ascension and Tristan da Cunha",
            "KN": "Saint Kitts and Nevis",
            "LC": "Saint Lucia",
            "MF": "Saint Martin (French part)",
            "PM": "Saint Pierre and Miquelon",
            "VC": "Saint Vincent and the Grenadines",
            "WS": "Samoa",
            "SM": "San Marino",
            "ST": "Sao Tome and Principe",
            "SA": "Saudi Arabia",
            "SN": "Senegal",
            "RS": "Serbia",
            "SC": "Seychelles",
            "SL": "Sierra Leone",
            "SG": "Singapore",
            "SX": "Sint Maarten (Dutch part)",
            "SK": "Slovakia",
            "SI": "Slovenia",
            "SB": "Solomon Islands",
            "SO": "Somalia",
            "ZA": "South Africa",
            "GS": "South Georgia and the South Sandwich Islands",
            "SS": "South Sudan",
            "ES": "Spain",
            "LK": "Sri Lanka",
            "SD": "Sudan",
            "SR": "Suriname",
            "SJ": "Svalbard and Jan Mayen",
            "SE": "Sweden",
            "CH": "Schweiz",
            "SY": "Syrian Arab Republic",
            "TW": "Taiwan",
            "TJ": "Tajikistan",
            "TZ": "Tanzania, United Republic of",
            "TH": "Thailand",
            "TL": "Timor-Leste",
            "TG": "Togo",
            "TK": "Tokelau",
            "TO": "Tonga",
            "TT": "Trinidad and Tobago",
            "TN": "Tunisia",
            "TR": "Turkey",
            "TM": "Turkmenistan",
            "TC": "Turks and Caicos Islands",
            "TV": "Tuvalu",
            "UG": "Uganda",
            "UA": "Ukraine",
            "AE": "United Arab Emirates",
            "GB": "United Kingdom",
            "UM": "United States Minor Outlying Islands",
            "US": "United States of America",
            "UY": "Uruguay",
            "UZ": "Uzbekistan",
            "VU": "Vanuatu",
            "VE": "Venezuela (Bolivarian Republic of)",
            "VN": "Viet Nam",
            "VG": "Virgin Islands (British)",
            "VI": "Virgin Islands (U.S.)",
            "WF": "Wallis and Futuna",
            "EH": "Western Sahara",
            "YE": "Yemen",
            "ZM": "Zambia",
            "ZW": "Zimbabwe",
            "AX": "Åland Islands",
            "XK": "Kosovo",
        },
        overlayData: {},
        overlayVisible: false,
    },
    mutations: {
        showHelpOverlay({ commit }, helpInterface) {
            console.log(helpInterface);
            this.state.overlayData = helpInterface;
            if(helpInterface.type == 'order' || helpInterface.type == 'invoice') {
                this.state.overlayData.type = helpInterface.type;
                this.state.overlayData.no = helpInterface.id;
            }
            document.getElementsByTagName('body')[0].style.overflow = "hidden";
            this.state.overlayVisible = true;
        },
        hideOverlay({ commit }) {
            this.state.overlayVisible = false;
            this.state.overlayData = {};
            document.getElementsByTagName('body')[0].style.overflow = "initial";
        }
    },
    actions: {
		login({commit}, user){
            console.log("Login triggered");
			var userData = new FormData();
			userData.append('email_address', user.email_address);
			userData.append('password', user.password);
            userData.append('id', user.crm_id);
            this.state.loginRequestState = 'loading';
            axios({
                method: 'post',
                url: '/api/login',
                crossDomain: true,
                withCredentials: true,
                baseURL: window.location.origin == 'https://portal.loxone.com' ? 'https://portal.loxone.com' : 'https://dev-portal.loxone.com',
                data: userData
            })
            .then(resp => {
                this.state.loginErrorMessage = '';
                this.state.loginRequestState = 'done';
                if (resp.data && resp.data.valid) {
                    axios({
                            method: 'post',
                            url: '/api/getPartnerData',
                            baseURL: window.location.origin == 'https://portal.loxone.com' ? 'https://portal.loxone.com' : 'https://dev-portal.loxone.com',
                            crossDomain: true,
                            withCredentials: true,
                        }).then(pDResponse => {

                            //Call evaluateJsonResponse to check for errors
                            this.dispatch('evaluateJsonResponse', pDResponse.data);

                            this.state.partnerData = pDResponse.data;
                            this.dispatch('buildPartnerPalette');
                            this.state.partnerData.loaded = true;
                            this.state.partnerData.loaded_from = 'api';
                        })
    
                    this.state.loggedIn = resp.data.valid;
                    console.log(resp.data);
                    if (resp.data.userData) {
                        this.state.userData.content = resp.data.userData;
                    }

                    let shop_instance = '';
                    if(resp.data.userData.account_nav_instance == 'CH') {
                        /*if(this.$i18n.locale == 'frfr') {
                            shop_instance = 'frch';
                        } else {
                            shop_instance = 'dech';
                        }*/
                    } else if(resp.data.userData.account_nav_instance == 'UK') {
                        shop_instance = 'enuk';
                    } else if(resp.data.userData.account_nav_instance == 'US') {
                        shop_instance = 'enus';
                    } else if(resp.data.userData.account_nav_instance == 'AT') {
                        switch(resp.data.userData.account_country) {
                            case 'AT':
                                shop_instance = 'deat';
                                break;
                            case 'DE':
                                shop_instance = 'dede';
                                break;
                            case 'ES':
                            case 'CA':
                                shop_instance = 'eses';
                                break;
                            case 'FR':
                                shop_instance = 'frfr';
                                break;
                            case 'IT':
                                shop_instance = 'itit';
                                break;
                            case 'NL':
                                shop_instance = 'nlnl';
                                break;
                            case 'PL':
                                shop_instance = 'plpl';
                                break;
                            case 'CZ':
                                shop_instance = 'cscz';
                                break;
                            case 'SK':
                                shop_instance = 'sksk';
                                break;
                            default:
                                shop_instance = 'enen';
                                break;
                        }
                    } else {
                        shop_instance = '';
                    }

                    if(user.shop && user.shop != 'undefined' && !user.redirect) {
                        window.location.href = "https://shop.loxone.com/" + shop_instance + "/checkout/cart/?checkout=redirect";
                    } else if(user.redirect){
                        window.location.href = user.redirect;
                    } else {
                        console.log('DASHBOARD');
                        router.push('/');
                    }
                }

                if(resp.data && resp.data.error_code) {
                    this.state.loginErrorMessage = resp.data.error_code;
                }
            })
		},
		logout(){
            localStorage.clear();
			this.state.loggedIn = false;
			this.state.userData = {
                loaded: false,
                loaded_from: '',
                content: {},
            };
			this.state.partnerData = {
                loaded: false,
                loaded_from: '',
                content: {},
            };
            this.partnerPalette = '';
			axios({
				method: 'post',
				url: '/api/logout',
                crossDomain: true,
                baseURL: window.location.origin == 'https://portal.loxone.com' ? 'https://portal.loxone.com' : 'https://dev-portal.loxone.com',
                withCredentials: true,
			})
			.then(() => {
				this.state.loggedIn = {
                    loaded: false,
                    loaded_from: '',
                    content: {},
                };
				this.state.userData = {
                    loaded: false,
                    loaded_from: '',
                    content: {},
                };
				router.go({
                    name: 'Login',
                    params: {
                        sidebar: false
            }
                });

			});
		},
		silentLogout(){
            localStorage.clear();
			this.state.loggedIn = false;
			this.state.userData = {
                loaded: false,
                loaded_from: '',
                content: {},
            };
			this.state.partnerData = {
                loaded: false,
                loaded_from: '',
                content: {},
            };
            this.partnerPalette = '';
			axios({
				method: 'post',
				url: '/api/logout',
                baseURL: window.location.origin == 'https://portal.loxone.com' ? 'https://portal.loxone.com' : 'https://dev-portal.loxone.com',
                crossDomain: true,
                withCredentials: true,
			})
			.then(() => {
				this.state.loggedIn = false;
				this.state.userData =  {
                    loaded: false,
                    loaded_from: '',
                    content: {},
                };

			});
		},
        buildPartnerPalette() {
            var layout = 'endcustomer';
            var primary = '';
            var secondary = '';
            var next_primary = '';
            var next_secondary = '';

            if(this.state.partnerData.partner_data.partner_status) {
                switch(this.state.partnerData.partner_data.partner_status.toLowerCase()) {
                    case 'flagship':
                    case 'flagship27':
                        layout = 'partner';
                        this.state.userGroups = ['partner'];
                        this.state.clearStatus = 'flagship';
                        primary = this.state.main.colors.flagship.primary;
                        secondary = this.state.main.colors.flagship.secondary;
                        next_primary = this.state.main.colors.flagship.next_primary;
                        next_secondary = this.state.main.colors.flagship.next_secondary;
                        break;
                    case 'disti gold':
                    case 'gold-sonde':
                    case 'gold23':
                        layout = 'partner'
                        this.state.userGroups = ['partner'];
                        this.state.clearStatus = 'gold';
                        primary = this.state.main.colors.gold.primary;
                        secondary = this.state.main.colors.gold.secondary;
                        next_primary = this.state.main.colors.gold.next_primary;
                        next_secondary = this.state.main.colors.gold.next_secondary;
                        break;
                    case 'platin-son':
                    case 'platin27':
                        layout = 'partner';
                        this.state.userGroups = ['partner'];
                        this.state.clearStatus = 'platin';
                        primary = this.state.main.colors.platin.primary;
                        secondary = this.state.main.colors.platin.secondary;
                        next_primary = this.state.main.colors.platin.next_primary;
                        next_secondary = this.state.main.colors.platin.next_secondary;
                        break;
                    case 'disti':
                    case 'reg neu':
                    case 'reg-sonder':
                    case 'burkhalter':
                        layout = 'partner';
                        this.state.userGroups = ['partner'];
                        this.state.clearStatus = 'disti';
                        primary = this.state.main.colors.registered.primary;
                        secondary = this.state.main.colors.registered.secondary;
                        next_primary = this.state.main.colors.registered.next_primary;
                        next_secondary = this.state.main.colors.registered.next_secondary;
                        break;
                    case 'reg10':
                    case 'reg15':
                    case 'reg21':
                    case 'reg25':
                        layout = 'partner';
                        this.state.userGroups = ['partner'];
                        this.state.clearStatus = 'reg_flex ' + this.state.partnerData.partner_data.partner_status.toLowerCase();
                        primary = this.state.main.colors.registered.primary;
                        secondary = this.state.main.colors.registered.secondary;
                        next_primary = this.state.main.colors.registered.next_primary;
                        next_secondary = this.state.main.colors.registered.next_secondary;
                    break;
                    case 'disti silv':
                    case 'silver':
                    case 'silver-son':
                        layout = 'partner';
                        this.state.userGroups = ['partner'];
                        this.state.clearStatus = 'silver';
                        primary = this.state.main.colors.silver.primary;
                        secondary = this.state.main.colors.silver.secondary;
                        next_primary = this.state.main.colors.silver.next_primary;
                        next_secondary = this.state.main.colors.silver.next_secondary;
                        break;
                    case 'quadral export':
                        layout = 'partner';
                        this.state.userGroups = ['partner'];
                        this.state.clearStatus = 'quadral export';
                        primary = this.state.main.colors.registered.primary;
                        secondary = this.state.main.colors.registered.secondary;
                        next_primary = this.state.main.colors.registered.next_primary;
                        next_secondary = this.state.main.colors.registered.next_secondary;
                        break;
                    case 'quadral reg':
                        layout = 'partner';
                        this.state.userGroups = ['partner'];
                        this.state.clearStatus = 'quadral reg';
                        primary = this.state.main.colors.registered.primary;
                        secondary = this.state.main.colors.registered.secondary;
                        next_primary = this.state.main.colors.registered.next_primary;
                        next_secondary = this.state.main.colors.registered.next_secondary;
                        break;
                    case 'quadral silver':
                        layout = 'partner';
                        this.state.userGroups = ['partner'];
                        this.state.clearStatus = 'quadral silver';
                        primary = this.state.main.colors.silver.primary;
                        secondary = this.state.main.colors.silver.secondary;
                        next_primary = this.state.main.colors.silver.next_primary;
                        next_secondary = this.state.main.colors.silver.next_secondary;
                        break;
                    case 'quadral gold':
                    case 'quadral gold/aurum':
                        layout = 'partner'
                        this.state.userGroups = ['partner'];
                        this.state.clearStatus = 'quadral gold';
                        primary = this.state.main.colors.gold.primary;
                        secondary = this.state.main.colors.gold.secondary;
                        next_primary = this.state.main.colors.gold.next_primary;
                        next_secondary = this.state.main.colors.gold.next_secondary;
                        break;
                    case 'quadral platin':
                    case 'quadral platin aurum':
                    case 'quadral platin/aurum':
                        layout = 'partner';
                        this.state.userGroups = ['partner'];
                        this.state.clearStatus = 'quadral platin';
                        primary = this.state.main.colors.platin.primary;
                        secondary = this.state.main.colors.platin.secondary;
                        next_primary = this.state.main.colors.platin.next_primary;
                        next_secondary = this.state.main.colors.platin.next_secondary;
                        break;
                    case 'ma':
                    case 'employee':
                        layout = 'endcustomer';
                        this.state.userGroups = ['ma'];
                        this.state.clearStatus = 'endcustomer';
                        primary = this.state.main.colors.endcustomer.primary;
                        secondary = this.state.main.colors.endcustomer.secondary;
                        next_primary = this.state.main.colors.endcustomer.next_primary;
                        next_secondary = this.state.main.colors.endcustomer.next_secondary;
                        break;
                    default:
                        layout = 'endcustomer';
                        this.state.userGroups = ['endcustomer'];
                        this.state.clearStatus = 'endcustomer';
                        primary = this.state.main.colors.endcustomer.primary;
                        secondary = this.state.main.colors.endcustomer.secondary;
                        next_primary = this.state.main.colors.endcustomer.next_primary;
                        next_secondary = this.state.main.colors.endcustomer.next_secondary;
                        break;
                }
                this.state.partnerPaletteBuilt = true;
            }


            this.state.partnerPalette = {
                'layout':   layout,
                'colors':   {
                    primary:        primary,
                    secondary:      secondary,
                    next_primary:   next_primary,
                    next_secondary: next_secondary,
                },

            };
        },
        evaluateJsonResponse({ commit }, response) {
            //IF response contains error
            if(response && response.error) {
                switch(response.error) {
                    case 'error.endpoint_failure':
                        this.state.endPointFailure = true;
                        break;
                    default:
                        this.state.endPointFailure = false;
                        break;
                }
            }
        }
    },
    modules: {
    },
    getters: {
        appVersion: (state) => {
            return state.packageVersion
        }
    }
})
