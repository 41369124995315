const ticketHandler = {
    data() {
        return {
        }
      },
    methods: {
        ticketHandler(department = 7515000000067043, subject = 'Loxone Portal Ticket', description = '-', files = null) {
            let ticketData = new FormData;
            ticketData.append('department', department);
            ticketData.append('ticket_subject', subject);
            ticketData.append('ticket_description', description);
            if(this.files && this.files.length) {
                for(var i = 0; i < this.files.length; i++ ){
                    let file = this.files[i];
                    ticketData.append('files[' + i + ']', file);
                }
            }
            console.log(subject, description, files);
            this.$axios({
                method: 'post',
                data: ticketData,
                url: '/api/createTicket',
            })
            .then(resp => {
                console.log(resp);
                if(this.$store.state.overlayData.type == 'loader') {
                    this.$store.state.overlayData.type = 'ticket_success';
                }
            });
        },
    }
}

export default ticketHandler