<template>
    <div v-if="$store.state.overlayVisible" class="overlay__wrapper" @click="hideOverlay($event.target)">
        <div class="overlay__content">
            <template v-if="$store.state.overlayData.type == 'order'">
                <h2>{{$t('orders.questions')}}</h2>
                <vinput :value="$store.state.overlayData.no" v-model="$store.state.overlayData.no" :label="$t('orders.order_id')" type="text" />
                <vinput v-model="$store.state.overlayData.message" :label="$t('tickets.message')" type="textarea" :layout="'border'" />
                <a @click="submitTicket()" class="btn">{{$t('input.submit')}}</a>
            </template>
            <template v-else-if="$store.state.overlayData.type == 'invoice'">
                <h2>{{$t('invoices.questions')}}</h2>
                <vinput :value="$store.state.overlayData.no" v-model="$store.state.overlayData.no" :label="$t('invoices.number')" type="text" />
                <vinput v-model="$store.state.overlayData.message" :label="$t('tickets.message')" type="textarea" :layout="'border'" />
                <a @click="submitTicket()" class="btn">{{$t('input.submit')}}</a>
            </template>
            <template v-else-if="$store.state.overlayData.type == 'loader'">
                <loader></loader>
            </template>
            <template v-else-if="$store.state.overlayData.type == 'ticket_success'">
                <h2>{{$t('tickets.ticket_success')}}</h2>
                <p>{{$t('tickets.ticket_success_info')}}</p>
            </template>
            <template v-else-if="$store.state.overlayData.type == 'ticket_error'">
                <h2>{{$t('tickets.ticket_error')}}</h2>
                <p>{{$t('tickets.ticket_error_info')}}</p>
            </template>
            <div @click="hideOverlay()" class="overlay__close">
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <line x1="0.353553" y1="0.646447" x2="17.3536" y2="17.6464" stroke="black"/>
                    <line x1="0.646447" y1="17.6464" x2="17.6464" y2="0.646447" stroke="black"/>
                </svg>
            </div>
        </div>
    </div>    
</template>

<script>
    /** components */
    import vinput from '@/components/Input.vue';
    import Loader from '@/components/Loader.vue';
    
    /** mixins */
    import ticketHandler from '../mixins/ticketHandler';

    export default {
        components: {
            vinput,
            Loader,
        },
        mixins: [ticketHandler],
        data() {
            return {
                
            }
        },
        methods: {
            hideOverlay(target = null) {
                if(target == null || target.classList.contains('overlay__wrapper') || target.classList.contains('overlay__close')) {
                    this.$store.commit('hideOverlay');
                }
            },
            submitTicket() {
                let department;
                let subject;
                let description;

                //Todo: switch department
                switch(this.$store.state.userData.content.account_country) {
                    case 'BE':
                    case 'NL':
                    case 'LU':
                        department = 7515000000082600; //DEPT_BENELUX_OFFICE
                        break;
                    case 'CH':
                        department = 7515000000093105; //DEPT_CH_OFFICE
                        break;
                    case 'CZ':
                        department = 7515000000086802; //DEPT_CZ_OFFICE
                        break;
                    case 'ES':
                        department = 7515000000099408; //DEPT_ES_OFFICE
                        break;
                    case 'FR':
                        department = 7515000000078398; //DEPT_FR_OFFICE
                        break;
                    case 'IT':
                        department = 7515000000091004; //DEPT_IT_OFFICE
                        break;
                    case 'PL':
                        department = 7515000150935435; //DEPT_PL_OFFICE
                        break;
                    case 'SK':
                        department = 7515000277726257; //DEPT_SK_OFFICE
                        break;
                    case 'TR':
                        department = 7515000370332134; //DEPT_TR_OFFICE
                        break;
                    case 'UK':
                        department = 7515000000073358; //DEPT_UK_OFFICE
                        break;
                    case 'US':
                        department = 7515000000103610; //DEPT_US_OFFICE
                        break;
                    default:
                        department = 7515000000069148; //DEPT_AT_OFFICE
                        break;
                }

                if(this.$store.state.overlayData.type == 'order') {
                    subject = 'Portal - Order #' + this.$store.state.overlayData.no;
                } else if(this.$store.state.overlayData.type == 'invoice') {
                    subject = 'Portal - Invoice #' + this.$store.state.overlayData.no;
                }

                if(this.$store.state.overlayData.message != '') {
                    description = this.$store.state.overlayData.message;
                }

                this.$store.state.overlayData.type = 'loader';
                this.ticketHandler(department, subject, description);
            }
        },
    }
</script>