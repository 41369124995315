<template>
    <Transition>
        <div v-show="elementVisible" class="alert" :class="getClass()">
            <template v-if="icon">
                <template v-if="type == 'success'">
                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="24px" height="24px" viewBox="0 0 24 24" enable-background="new 0 0 24 24" xml:space="preserve">
                        <polyline class="stroke" fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="17,8.5  9.5,15.5 7,13"/>			
                        <circle class="stroke" fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="12" cy="12" r="11.5"/>
                    </svg>
                </template>
                <template v-else-if="type == 'danger' || type == 'warning'">
                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="24px" height="24px" viewBox="0 0 24 24" enable-background="new 0 0 24 24" xml:space="preserve">
                        <path class="stroke" fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M22.498,12.31 c0.105,6.075-4.923,11.086-10.998,11.192c-6.074,0.104-10.893-4.734-10.998-10.81C0.396,6.619,5.426,1.606,11.5,1.502 C17.574,1.396,22.393,6.236,22.498,12.31z"/>
                        <line class="stroke" fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" x1="11.5" y1="14" x2="11.5" y2="7"/>
                        <path class="stroke" fill="none" stroke="#000000" stroke-linejoin="round" stroke-miterlimit="10" d="M12,17.5c0,0.276-0.224,0.5-0.5,0.5l0,0 c-0.276,0-0.5-0.224-0.5-0.5l0,0c0-0.276,0.224-0.5,0.5-0.5l0,0C11.776,17,12,17.224,12,17.5L12,17.5z"/>
                    </svg>
                </template>
                <template v-else>
                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="24px" height="24px" viewBox="0 0 24 24" enable-background="new 0 0 24 24" xml:space="preserve">
                        <circle class="stroke" fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="11.5" cy="12.5" r="11"/>
                        <line class="stroke" fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" x1="8.5" y1="19.5" x2="14.5" y2="19.5"/>
                        <polyline class="stroke" fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="9.5,10.5 11.5,10.5 11.5,19"/>
                        <path class="stroke" fill="none" stroke="#000000" stroke-linejoin="round" stroke-miterlimit="10" d="M11,6c-0.277,0-0.5,0.225-0.5,0.5 C10.5,6.777,10.723,7,11,7c0.275,0,0.5-0.223,0.5-0.5C11.5,6.225,11.275,6,11,6L11,6z"/>		
                    </svg>
                </template>
            </template>
            <span v-html="text"></span>
        </div>
    </Transition>
</template>

<script>
    /**
     * @file Alert.vue
     * @author @marcogabriel
     * @description Simple alert box component
     *              Heavily inspired by https://getbootstrap.com/docs/4.0/components/alerts/
     */

    export default {
        name: "Alert",
        data() {
            return {
                elementVisible: true,
            }
        },
        props: {
            type: {
                type: String,
                required: false,
            },
            time: {
                type: Number,
                required: false,
            },
            icon: {
                type: Boolean,
                required: false,
            },
            text: {
                type: String,
                required: false,
            }
        },
        methods: {
            getClass() {
                var $className = '';
                $className += 'alert-' + this.type;

                if(this.icon) {
                    $className += ' alert-icon';
                }

                return $className;
            }
        },
        created() {
            if(this.time) {
                setTimeout(() => this.elementVisible = false, this.time)
            }
        },
    }
</script>


<style lang="scss" scoped>
    .v-enter-active,
    .v-leave-active {
    transition: opacity 0.5s ease;
    }

    .v-enter-from,
    .v-leave-to {
    opacity: 0;
    }
    .alert{
        position: relative;
        padding: 0.75rem 1.25rem;
        margin-bottom: 1rem;
        border: 1px solid transparent;
        display: flex;
        align-items: center;

        svg{
            margin-right: 10px;
        }

        &.alert-success {
            color: #4A9834;
            background-color: rgba(105, 195, 80, 0.2);
            border-color: #4A9834;

            svg .stroke{
                stroke: #4A9834;
            }
        }

        &.alert-danger {
            color: #E73246;
            background-color: rgba(231, 50, 70, 0.2);
            border-color: #E73246;

            svg .stroke{
                stroke: #E73246;
            }
        }

        &.alert-warning {
            color: #F49516;
            background-color: rgba(247, 181, 92, 0.2);
            border-color: #F49516;

            svg .stroke{
                stroke: #F49516;
            }
        }
        &.alert-info {
            color: #007B99;
            background-color: rgba(0, 176, 219, 0.2);
            border-color: #007B99;

            svg .stroke{
                stroke: #007B99;
            }
        }
    }
</style>