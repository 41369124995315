<template>
    <div>
        <template v-if="getInputGroup() == 'checkboxes'">
            <div class="input__wrapper input__group--checkboxes">
                <input :disabled="checked" :placeholder="placeholder" :id="name" :name="name" :type="type" v-model="checked" @change="onChange">
                <label v-if="label" :class="{'required' : required}" :for="name">{{label}}</label>
            </div>
        </template>
        <template v-else-if="getInputGroup() == 'dropdown'">
            <label v-if="label" :class="{'required' : required}" :for="name">{{label}}</label>
            <div @focus="handleFocus" @focusout="handleFocusOut" tabindex="0" class="fake__dropdown" :class="{'open' : dropdownVisible}">
                <div @click="toggleDropdownVisible()" class="input--fake">
                    <template v-if="this.selectedDropdownValues.length > 0">
                        <template :key="index" v-for="(value, index) in this.selectedDropdownValues">
                            <span v-if="$te('partner_references.application_areas.' + value)">{{$t('partner_references.application_areas.' + value)}}</span>
                            <span v-else-if="$te('partner_references.types.' + value)">{{$t('partner_references.types.' + value)}}</span>
                            <span v-else-if="$te('invoices.states.' + value)">{{$t('invoices.states.' + value)}}</span>
                            <span v-else-if="$te('order.state.' + value)">{{$t('order.state.' + value)}}</span>
                            <span v-else-if="$te('tickets.state.' + value)">{{$t('tickets.state.' + value)}}</span>
                            <span v-else-if="$te('message_center.taglist.' + value)">{{$t('message_center.taglist.' + value)}}</span>
                            <span :class="'idx idx--'+value" v-else>{{data[value]}}</span>
                            <template v-if="index < this.selectedDropdownValues.length - 1">
                                <span>, </span>
                            </template>
                        </template>
                    </template>
                    <template v-else>
                        {{placeholder}}
                    </template>
                    <svg width="19" height="12" viewBox="0 0 19 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <line y1="-1" x2="10.9432" y2="-1" transform="matrix(0.71003 0.704171 -0.71003 0.704171 0 2.28516)" stroke="black" stroke-width="2"/>
                        <line y1="-1" x2="12.7668" y2="-1" transform="matrix(-0.709255 0.704952 -0.709255 -0.704952 16.8167 1)" stroke="black" stroke-width="2"/>
                    </svg>
                </div>
                <div v-if="dropdownVisible" class="fake__dropdown--container">
                    <div :class="[{selected: (selectedDropdownValues.includes(value))},  'idx idx--'+value]" @click="toggleSelectValues(value)" :key="value" v-for="(name, value) in data">{{name}}</div>
                </div>
            </div>
        </template>
        <template v-else-if="getInputGroup() == 'datepicker'">
            <div class="fake__datepicker">
                <Datepicker :timezone="'UTC'" :enableTimePicker="false" :placeholder="placeholder" autoApply :locale="data.locale" :format="data.format" range v-model="localValue"></Datepicker>
                <svg v-if="!value" width="19" height="12" viewBox="0 0 19 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <line y1="-1" x2="10.9432" y2="-1" transform="matrix(0.71003 0.704171 -0.71003 0.704171 0 2.28516)" stroke="black" stroke-width="2"/>
                    <line y1="-1" x2="12.7668" y2="-1" transform="matrix(-0.709255 0.704952 -0.709255 -0.704952 16.8167 1)" stroke="black" stroke-width="2"/>
                </svg>
                <svg v-else @click="value = ''" style="right: 20px" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <line x1="0.353553" y1="0.646447" x2="17.3536" y2="17.6464" stroke="black"/>
                    <line x1="0.646447" y1="17.6464" x2="17.6464" y2="0.646447" stroke="black"/>
                </svg>
            </div>
        </template>
        <template v-else-if="getInputGroup() == 'search'">
            <div class="fake__search">
                <input @input="$emit('update:modelValue', $event.target.value)" type="text" :placeholder="placeholder">
                <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.9578 11.9956C8.09499 11.9956 5.7653 9.63029 5.7653 6.72224C5.7653 3.81506 8.09499 1.44971 10.9578 1.44971C13.8207 1.44971 16.1504 3.81506 16.1504 6.72224C16.1504 9.63029 13.8207 11.9956 10.9578 11.9956ZM10.9578 0.586914C7.62599 0.586914 4.91566 3.33923 4.91566 6.72224C4.91566 8.25931 5.47897 9.66222 6.40125 10.7398L0.124578 17.1133C-0.0415258 17.282 -0.0415258 17.5551 0.124578 17.7233C0.207417 17.8079 0.31617 17.8497 0.424923 17.8497C0.533676 17.8497 0.642429 17.8079 0.725268 17.7233L7.00194 11.3498C8.06355 12.2868 9.44421 12.8584 10.9578 12.8584C14.2897 12.8584 17 10.1057 17 6.72224C17 3.33923 14.2897 0.586914 10.9578 0.586914Z" fill="black"/>
                </svg>
            </div>
        </template>
        <template v-else-if="getInputGroup() == 'textarea'">
            <div class="input__wrapper input__group--textarea" :class="layout">
                <label v-if="label" :class="{'required' : required}" :for="name">{{label}}</label>
                <textarea :id="name" :name="name" v-model="localValue" :placeholder="placeholder" @input="$emit('update:modelValue', $event.target.value)"></textarea>
            </div>
        </template>
        
        <template v-else-if="getInputGroup() == 'file'">
            <div class="input__wrapper input__group--file">
                <label v-if="label" :class="{'required' : required}" :for="name">{{label}}</label>
                <input :placeholder="placeholder" :id="name" :name="name" :type="type" v-model="localValue" :multiple="multiple" :accept="acceptedTypes" @change="$emit('update:modelValue', $event.target.files)">
            </div>
        </template>
        <template v-else-if="getInputGroup() == 'image_overlay'">
            <div class="input__wrapper input__group--file_overlay">
                <p class="label" :class="{'required' : required}">{{label}}</p>
                <label v-if="label" class="image__overlay--label" :class="{'filled' : bg}" :for="name">
                    <img v-if="bg" :src="bg" />
                    <div v-else class="label__wrapper">
                        <div class="label--top">{{$t('input.upload_image')}}</div>
                        <div class="label--bottom">({{$t('input.accepted_file_types')}} jpg, png, gif, {{$t('input.max_file_size')}} 2MB)</div>
                    </div>
                </label>
                <div v-if="bg" @click="$emit('deleteBackground')" class="delete__wrapper">                
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <line x1="0.353553" y1="0.646447" x2="17.3536" y2="17.6464" stroke="black"/>
                        <line x1="0.646447" y1="17.6464" x2="17.6464" y2="0.646447" stroke="black"/>
                    </svg>
                </div>
                <input :ref="asdf" :placeholder="placeholder" :id="name" :name="name" type="file" :accept="acceptedTypes" @change="$emit('update:modelValue', $event.target.files)">
            </div>
        </template>
        <template v-else>
            <div class="input__wrapper input__group--texts" :class="layout">
                <label v-if="label" :class="{'required' : required}" :for="name">{{label}}</label>
                <input @focus="$emit('focus')" @blur="$emit('blur')" :class="{'readonly' : readonly}" :role="role" :maxlength="maxlength" :disabled="readonly" :autocomplete="autocomplete" :autofocus="autofocus" :placeholder="placeholder" :id="name" :name="name" :type="type" v-model="localValue" @input="$emit('update:modelValue', $event.target.value)">
            </div>
        </template>
        <template v-if="false">
            <input type="button">
            <input type="color">
            <input type="file">
            <input type="hidden">
            <input type="image">
            <input type="number">
            <input type="range">
            <input type="reset">
            <input type="search">
            <input type="submit">
            <input type="tel">
            <input type="text">
            <input type="url">
        </template>
    </div>
</template>

<script>
    /**
     * @file Input.vue
     * @author @marcogabriel
     * @description Global input component, not all input's are included.
     *              • As soon as a new input type is necessary, it has to be added here.
     * 
     * Todo: Design Issue - Same input does not look the same on all pages. (Magda / Tuana) => Redesign
     */

    /** components */
    import Datepicker from '@vuepic/vue-datepicker';
    import '@vuepic/vue-datepicker/dist/main.css';
    
    export default {
        components: {
            Datepicker,
        },
        name: 'vinput',
        computed: {
            checked: {
                get () {
                    return this.value
                },
                set (val) {
                    this.checkedProxy = val
                }
            }
        },
        props: {
            type: {
                type: String,
                required: true,
            },
            name: {
                required: false,
            },
            value: {
                required: false,
            },
            label: {
                type: String,
                required: false,
            },
            placeholder: {
                type: String,
                required: false,
            },
            role: {
                type: String,
                required: false,
            },
            required: {
                type: Boolean,
                required: false,
            },
            data: {
                type: Object,
                required: false,
            },
            bg: {
                type: String,
                required: false,
            },
            layout: {
                type: String,
                required: false,
            },
            autofocus: {
                type: Boolean,
                required: false,
            },
            autocomplete: {
                type: String,
                required: false,
            },
            readonly: {
                type: Boolean,
                required: false,
            },
            acceptedTypes: {
                type: String,
                required: false,
            },
            maxlength: {
                type: Number,
                required: false,
            },
            multiple: {
                type: Number,
                required: false,
            }
        },
        data() {
            return {
                dropdownVisible: false,
                checkedProxy: false,
                selectedDropdownValues: [],
                localValue: this.value,
                localChecked: this.value,
            }
        },
        watch: {
            localValue: {
                deep: true,
                handler() {
                    if(this.type == 'datepicker') {
                        this.$emit('update:modelValue', this.localValue);
                    }
                    this.$emit('click', this.localValue);
                }
            },
            value: {
                deep: true,
                handler() {
                    if(this.type == 'datepicker') {
                        this.$emit('update:modelValue', this.value);
                    }
                    this.$emit('click', this.value);
                }
            }
        },
        emits: ['click', 'update:modelValue', 'input', 'deleteBackground', 'focus', 'blur'],
        methods: {
            onChange: function(e) {
                this.$emit('input', this.checkedProxy)
                
            },
            callback: function(e) {
                this.$emit('click', e);
            },
            getInputGroup() {
                var group = "text";
                switch(this.type) {
                    case 'checkbox':
                    case 'radio':
                        group = 'checkboxes';
                        break;
                    case 'dropdown':
                    case 'dropdown-multiple':
                        group = 'dropdown';
                        break;
                    case 'datepicker':
                        group = 'datepicker';
                        break;
                    case 'search':
                        group = 'search';
                        break;
                    case 'file':
                        group = 'file';
                        break;
                    case 'textarea':
                        group = 'textarea';
                        break;
                    case 'image_overlay':
                        group = 'image_overlay';
                        break;
                }
                return group;
            },
            toggleDropdownVisible() {
                this.dropdownVisible = !this.dropdownVisible;
            },
            toggleSelectValues(value) {
                if(this.type == 'dropdown') {
                    this.selectedDropdownValues = [];
                }
                if(!this.selectedDropdownValues.includes(value)) {
                    this.selectedDropdownValues.push(value);
                } else {
                    this.selectedDropdownValues.splice(this.selectedDropdownValues.indexOf(value), 1);
                }
                    this.$emit('update:modelValue', this.selectedDropdownValues);
            },
            handleFocusOut() {
                this.dropdownVisible = false;
            }
        },
    }
</script>

<style lang="scss">
    @import "@/scss/utilities/_variables.scss";

    textarea {
        resize: none;
    }
    input.readonly{
        color: #BDBDBD;
    }
    
    label{
        padding-bottom: 2px;
        display: inline-block;
        font-family: $ff-averta-extrabold;
        cursor: pointer;

        &.required:after{
            content: '*';
            padding-left: 2px;
            color: red;
        }
    }

	@import "@/scss/utilities/_inputs.scss";
</style>